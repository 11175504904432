<template>
  <transition name="fade">

    <div class="d-flex flex-column flex-xl-row" v-if="!state.loaderEnabled">
      <!--begin::Content-->
      <div class="flex-lg-row-fluid">
        <div style='line-height: 70px;  margin-top: -18px; background: #f4f4f500; margin-left: -30px; margin-right: -30px; padding-left: 30px; padding-right: 30px; margin-bottom: 8px;'>
          <ul class="nav nav-custom nav-pills border-0 fs-4 fw-bold mb-8" style="display: inline;">
            <li class="nav-item" style="float: left; background:none; color: #4fa0c2;">
              <h1 class="text-gray-600 fw-bolder mt-2 my-1 fs-5 bg-title mb-5 card">
                <span v-html="pageTitle"></span><span v-if="state.bordereau.bor_n_readonly" class="text-danger fw-bolder">(LECTURE SEULE)</span>
              </h1>
            </li>
            <li class="nav-item " style="float: right;" >
              <BordPDF title="" class="btn btn-secondary text-active-light me-6 ml-6 btn-custom-white h-45px" :bor_n_seq="state.bor_n_seq" />
            </li>
          </ul>
        </div>


      <div class="card mb-7" style="width: 100%;">
        <div class="card-header border-0 pt-6">
          <div class="card-title" style="width:100%;">
            <h2>Traitement du bordereau</h2>
          </div>
        </div>
        <div class="card-body pt-0">
          
          <div class="row row-user">
            <div class="col-sm-12 col-xxl-3 p-4"><span style="font-size: 15px;"><strong>Type de bordereau</strong></span></div>
            <div class="col"> 
              <div class="mb-3" style='width: 100%; text-align: center; background: #f7f7f7; padding: 10px;' >
                <el-radio-group v-model="form.bor_c_sort_versement" size="large">
                  <el-radio-button v-if="state.typeBord==1" label="Conservation intégrale" :disabled="state.bordereau.bor_n_readonly" />
                  <el-radio-button v-if="state.typeBord==1" label="Conservation partielle"  :disabled="state.bordereau.bor_n_readonly" />
                  <el-radio-button v-if="state.typeBord==2"  label="Elimination" :disabled="state.bordereau.bor_n_readonly" />
                </el-radio-group>
                
                <a class="btn btn-sm btn-light-primary btn-custom-white btn-mid-width mx-5" @click='switchTypeBord()' v-if="state.typeBord!=0">
                  <span class="svg-icon svg-icon-1 svg-icon-primary">
                  <inline-svg src="media/icons/duotune/arrows/arr059.svg"/> Basculer sur un bordereau {{ state.basculeBord }} </span>
                </a>

              </div>
            </div>
          </div>

          
          <div class="row row-user" v-show="form.bor_c_sort_versement!='Conservation intégrale'">
            <div class="col-sm-12 col-xxl-3 p-5"><span style="font-size: 15px;"><strong>Nombre d'articles à éliminer</strong></span></div>
              <div class="col"> 
                <el-input v-model="form.bor_n_nb_article_elimination" placeholder="" style="max-width: 220px;" :disabled="state.bordereau.bor_n_readonly">
                  <template #append v-if="form.bor_c_sort_versement=='Elimination'"> 
                    <el-tooltip content="Calculer le nombre total d'article du bordereau" placement="right">
                      <a class="btn btn-sm btn-light-primary btn-custom-white btn-mid-width" @click='totArticle()'>
                        <span class="svg-icon svg-icon-1 svg-icon-primary" style="margin-right: 0rem;">
                        <inline-svg src="media/icons/duotune/arrows/arr059.svg"/> </span>
                      </a>
                    </el-tooltip>
                  </template>
                </el-input>

            </div>
          </div>

          <div class="row row-user" v-show="form.bor_c_sort_versement=='Elimination'">
            <div class="col-sm-12 col-xxl-3 p-5"><span style="font-size: 15px;"><strong>Années / Dates extrêmes</strong></span></div>
              <div class="col"> 
                <el-input v-model="form.bor_c_annee_mini" placeholder="" class="" style="max-width: 147px;" :disabled="state.bordereau.bor_n_readonly">
                </el-input>
                <el-input v-model="form.bor_c_annee_maxi" placeholder="" class="m-4" style="max-width: 147px; display: -webkit-inline-box;" :disabled="state.bordereau.bor_n_readonly">
                  <template #append> 
                    <el-tooltip content="Calculer les années extrêmes" placement="right">
                      <a class="btn btn-sm btn-light-primary btn-custom-white btn-mid-width" @click='anneesExtremes()'>
                        <span class="svg-icon svg-icon-1 svg-icon-primary" style="margin-right: 0rem;">
                        <inline-svg src="media/icons/duotune/arrows/arr059.svg"/> </span>
                      </a>
                    </el-tooltip>
                  </template>
                </el-input>
            </div>
          </div>

          <div class="row row-user" v-show="form.bor_c_sort_versement=='Elimination'">
            <div class="col-sm-12 col-xxl-3 p-5"><span style="font-size: 15px;"><strong>Métrage</strong></span></div>
              <div class="col"> 
                <el-input v-model="form.bor_n_papier_metrage" placeholder="" style="max-width: 203px;" :disabled="state.bordereau.bor_n_readonly">
                  <template #append> 
                    ml&nbsp;
                    <el-tooltip content="Calculer le métrage linaire des articles du bordereau" placement="right">
                      <a class="btn btn-sm btn-light-primary btn-custom-white btn-mid-width" @click='totMetrage()'>
                        <span class="svg-icon svg-icon-1 svg-icon-primary" style="margin-right: 0rem;">
                        <inline-svg src="media/icons/duotune/arrows/arr059.svg"/> </span>
                      </a>
                    </el-tooltip>
                  </template>
                </el-input>
            </div>
          </div>



          <div class="row row-user">
            <div class="col-sm-12 col-xxl-3 p-5"><span style="font-size: 15px;"><strong>Mot-clés</strong></span></div>
              <div class="col"> 
                <div style="border: 1px solid #dcdfe6;   padding: 3px;  margin-right: 0px;">
                  <el-tag v-for="tag in form.bor_c_keyword" :key="tag" class="mx-1" :closable="!state.bordereau.bor_n_readonly" :disable-transitions="false" @close="handleClose(tag)" >
                    {{ tag }}
                  </el-tag>
                  <el-input v-if="inputVisible" ref="InputRef" v-model="inputValue" class="ml-1" style="width:150px;" size="small" @keyup.enter="handleInputConfirm" @blur="handleInputConfirm"/>
                  <el-button v-else class="button-new-tag ml-1" size="small" @click="showInput" v-show="!state.bordereau.bor_n_readonly"  :disabled="state.bordereau.bor_n_readonly" >+ Nouveau Mot-clé</el-button>
                </div>
            </div>
           </div>


          <div class="row row-user">
            <div class="col-sm-12 col-xxl-3 p-5"><span style="font-size: 15px;"><strong>Référence réglementaire</strong></span></div>
              <div class="col"> 
                <el-input
                  v-model="form.bor_c_ref_reglementaire"
                  :rows="3"
                  type="textarea"
                  :disabled="state.bordereau.bor_n_readonly"
                  placeholder="Spécifier ici les références réglementaires, si existante..."
                />
            </div>
           </div>


        </div>
      </div>

      <div class="card mb-7 " style="width: 100%;" v-if="form.bor_c_sort_versement!='Elimination'">
        <div class="card-header border-0 pt-6">
          <div class="card-title" style="width:100%;">
            <h2>Délai et durée</h2>
          </div>
        </div>
        <div class="card-body pt-0">


          <div class="row row-user">
            <div class="col-sm-12 col-xxl-3 p-4"><span style="font-size: 15px;"><strong>Délai de communicabilité maximum</strong></span></div>
            <div class="col"> 
                <el-input v-model="form.bor_n_delai_communicabilite" style='width: 150px;' :disabled="state.bordereau.bor_n_readonly"  >
                 <template #append> 
                    an(s)
                  </template>
                </el-input>
            </div>
          </div>

          <div class="row row-user">
            <div class="col-sm-12 col-xxl-3 p-4"><span style="font-size: 15px;"><strong>Communicabilité totalité du versement</strong></span></div>
            <div class="col"> 
                <el-date-picker
                  v-model="form.bor_d_communicabilite"
                  type="date"
                  placeholder="Choix de la date"
                  style="float: left;padding: 0px"
                  :default-value="new Date()"
                  :disabled="state.bordereau.bor_n_readonly" 
                >
                  
                </el-date-picker>
            </div>
          </div>

          <div class="row row-user">
            <div class="col-sm-12 col-xxl-3 p-4"><span style="font-size: 15px;"><strong>DUA</strong></span></div>
            <div class="col"> 
                <el-input v-model="form.bor_n_dua" style='width: 150px;' :disabled="state.bordereau.bor_n_readonly"  >
                 <template #append> 
                    an(s)
                  </template>
                </el-input>
            </div>
          </div>

        </div>
      </div>      

    
      <div class="card" style="width: 100%;">
        <div class="card-header border-0 pt-6">
          <div class="card-title" style="width:100%;">
            <h2>Identification du service</h2>
          </div>
        </div>
        <div class="card-body pt-0">

          <div class="row row-user">
            <div class="col-sm-12 col-xxl-3 p-4"><span style="font-size: 15px;"><strong>{{ state.bordereau.act_c_raison_sociale }}</strong></span></div>
          </div>
          <div class="row row-user" v-if="state.bordereau.act_ark_c_nom2 ">
            <div class="col-sm-12 col-xxl-3 p-4"><span style="font-size: 15px;"><strong>{{ state.bordereau.act_ark_c_nom2 }}</strong></span></div>
          </div>
          <div class="row row-user" v-if="state.bordereau.act_ark_c_nom3">
            <div class="col-sm-12 col-xxl-3 p-4"><span style="font-size: 15px;"><strong>{{ state.bordereau.act_ark_c_nom3 }}</strong></span></div>
          </div>
          <div class="row row-user" v-if="state.bordereau.act_ark_c_nom4">
            <div class="col-sm-12 col-xxl-3 p-4"><span style="font-size: 15px;"><strong>{{ state.bordereau.act_ark_c_nom4 }}</strong></span></div>
          </div>

        </div>
      </div>


      <div class="card mt-5">
        <div class="card-header border-0 pt-6">
          <div class="card-title" style="width:100%;">
            <h2>Identification du service</h2>
          </div>
        </div>
        <div class="card-body pt-0">
          <el-row :gutter="30" class="mt-5">
            <el-col :span="12">
              <div class="row row-user">
                <h5>Rédacteur</h5>
                <select class="form-select m-2 mt-0" aria-label="Selection" v-model="form.bor_knum_utilisateur" :disabled="state.bordereau.bor_n_readonly">
                  <option v-for="item in state.lUsers" :value="item.uti_n_seq" :key="item.uti_n_seq">{{item.uti_c_prenom}} {{item.uti_c_nom}} (<i>{{ item.uti_c_mail }}</i>)</option>
                </select>

              </div>
            </el-col>   
            <el-col :span="12">
              <div class="row row-user">
                <h5>Archiviste</h5>
                <select class="form-select m-2 mt-0" aria-label="Selection" v-model="form.bor_knum_archiviste" :disabled="state.bordereau.bor_n_readonly">
                  <option v-for="item in state.lUsers" :value="item.uti_n_seq" :key="item.uti_n_seq">{{item.uti_c_prenom}} {{item.uti_c_nom}} (<i>{{ item.uti_c_mail }}</i>)</option>
                </select>


              </div>
            </el-col>  
          </el-row>

          <el-row :gutter="30" class="mt-5">
            <el-col :span="12">
              <div class="row row-user">
                <h5>Chef de service</h5>

                <select class="form-select m-2 mt-0" aria-label="Selection" v-model="form.bor_knum_chef_service" :disabled="state.bordereau.bor_n_readonly">
                  <option value=""><i>Aucun Chef de service</i></option>
                  <template v-for="item in state.lUsers" :key="item.uti_n_seq">
                    <option v-if="item.uti_n_seq!=form.bor_knum_archiviste" :value="item.uti_n_seq" >{{item.uti_c_prenom}} {{item.uti_c_nom}} (<i>{{ item.uti_c_mail }}</i>)</option>
                  </template>                  
                </select>

              </div>
            </el-col>   
            <el-col :span="12">
              <div class="row row-user">
                <h5>Direction des archives</h5>
                <select class="form-select m-2 mt-0" aria-label="Selection" v-model="form.bor_knum_direction_archive" :disabled="state.bordereau.bor_n_readonly">
                  <option value=""><i>Aucune direction des archives</i></option>
                  <option v-for="item in state.lUsers" :value="item.uti_n_seq" :key="item.uti_n_seq">{{item.uti_c_prenom}} {{item.uti_c_nom}} (<i>{{ item.uti_c_mail }}</i>)</option>
                </select>
                
              </div>
            </el-col>  
          </el-row>

        </div>
      </div>

    </div>
  </div>

  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, watch, nextTick, computed } from "vue";
import { ElInput } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import { useStore } from "vuex";
import * as moment from "moment";
import { Actions } from "@/store/enums/StoreEnums";

import BordPDF from "@/views/still/bord/pdf.vue"; 

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Overview",
  components: {
    BordPDF
  },
  
  setup() {

    const store = useStore();
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    moment.default.locale("fr");
    const state = reactive({
      bor_n_seq : parseInt(localStorage.getItem("currentBord") || "0"),
      bordereau: {} as any,
      loaderEnabled: true,
      initialMyList: [],
      loadingDatatable: 0,
      lUsers: [] as any,
      basculeBord: '',
      typeBord: 0,
    });


    const form = reactive({
      bor_c_sort_versement : '',
      bor_c_ref_reglementaire: '',
      bor_n_delai_communicabilite: '',
      bor_d_communicabilite: '' as any,
      bor_n_dua: '',
      bor_c_keyword: [] as any,
      bor_n_seq : 0,
      bor_c_service_versant: '',
      bor_c_direction: '',
      bor_c_sous_direction: '',
      bor_knum_utilisateur : 0,
      bor_knum_archiviste : 0,
      bor_knum_chef_service : 0 as any,
      bor_knum_direction_archive: 0 as any,
      bor_n_nb_article_elimination:0,
      bor_c_annee_mini : '',
      bor_c_annee_maxi : '',
      bor_n_papier_metrage : ''
    })

    const tableHeader = ref([
      { name: "N° d'ordre", key: "art_n_num_ordre", sortable: false,},
      { name: "Titre", key: "art_c_categorie", sortable: false,},
      { name: "Libellé", key: "art_c_libelle", sortable: false,},
      { name: "Dates", key: "art_n_annee_mini", sortable: false,},
      { name: "DUA", key: "art_n_dua", sortable: false,},
      { name: "Observations", key: "art_c_observations", sortable: false,},
      { name: "", key: "art_n_seq", sortable: false,},

    ]);

    const tableFilters = ref([
      { name: "titre", key: "art_c_categorie", label: "art_c_categorie", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const totArticle = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticles/" + parseInt(localStorage.getItem("currentBord") || "0"));
      form.bor_n_nb_article_elimination = myListLiv.results.length;
    };    

    const inputValue = ref('')
    const inputVisible = ref(false)
    const InputRef = ref<InstanceType<typeof ElInput>>()

    const handleClose = (tag: string) => {
      form.bor_c_keyword.splice(form.bor_c_keyword.indexOf(tag), 1)
    }

    const showInput = () => {
      inputVisible.value = true
      nextTick(() => {
        if (InputRef.value) InputRef.value.input.focus()
      })
    }

    const handleInputConfirm = () => {
      if (inputValue.value) {
        form.bor_c_keyword.push(inputValue.value)
      }
      inputVisible.value = false
      inputValue.value = ''
    }

    watch(form, () => {
      editBordereau();
    });

    const switchTypeBord = async () => {

    const newBord = form.bor_c_sort_versement == 'Conservation intégrale' || form.bor_c_sort_versement == 'Conservation partielle' ? 'Elimination' : 'Conservation intégrale'

    let textDisplay = "Souhaitez-vous réellement basculer ce bordereau en bordereau de ";
    textDisplay += form.bor_c_sort_versement == 'Conservation intégrale' || form.bor_c_sort_versement == 'Conservation partielle' ? "d'élimination ?" : 'de versement ?';
    
    ElMessageBox.confirm(
      textDisplay, 'Confirmation',
      {
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        type: '',
      }
    )
      .then(() => {
        let newObj = JSON.parse(JSON.stringify(form))
        newObj.bor_c_sort_versement = newBord;

        const currentBordType = newObj.bor_c_sort_versement == 'Conservation intégrale' || newObj.bor_c_sort_versement == 'Conservation partielle' ? 'versement' : 'élimination'
        localStorage.setItem("currentBordType", currentBordType);
        store.dispatch(Actions.SET_CURRENT_BORD, {bordSeq: newObj.bor_n_seq, bordType : currentBordType });      

        mAxiosApi
          .post("/editBord/", newObj)
          .then(() => {
            window.location.reload();
          })
      })
      .catch(() => {
        // Annulé
      })


    }
    
    const editBordereau = () => {
      form.bor_d_communicabilite = form.bor_d_communicabilite ? moment.default(form.bor_d_communicabilite).format("YYYY-MM-DD") : null;
      form.bor_knum_chef_service = form.bor_knum_chef_service ? form.bor_knum_chef_service : null;
      form.bor_knum_direction_archive = form.bor_knum_direction_archive ? form.bor_knum_direction_archive : null;
      if (form.bor_n_seq> 0 ) mAxiosApi.post("/editBord", form)
    };

    onMounted(async () => {

      const myListUsers = await getAxios("/getSVUsers");
      state.lUsers = myListUsers.results;

      const myListLiv = await getAxios("/getSVoneBord/"+ parseInt(localStorage.getItem("currentBord") || "0"));
      const res = myListLiv.results;
      state.bordereau = myListLiv.results

      form.bor_c_sort_versement = res.bor_c_sort_versement;
      form.bor_c_ref_reglementaire = res.bor_c_ref_reglementaire;
      form.bor_n_delai_communicabilite = res.bor_n_delai_communicabilite;
      form.bor_d_communicabilite = res.bor_d_communicabilite;
      form.bor_n_dua = res.bor_n_dua;
      form.bor_c_keyword = res.bor_c_keyword ? res.bor_c_keyword.split("||") : [];
      form.bor_c_service_versant= res.bor_c_service_versant;
      form.bor_c_direction= res.bor_c_direction;
      form.bor_c_sous_direction= res.bor_c_sous_direction;
      form.bor_knum_utilisateur = res.bor_knum_utilisateur;
      form.bor_knum_archiviste = res.bor_knum_archiviste;
      form.bor_knum_chef_service = res.bor_knum_chef_service;
      form.bor_knum_direction_archive = res.bor_knum_direction_archive;
      form.bor_n_nb_article_elimination = res.bor_n_nb_article_elimination;
      form.bor_c_annee_mini = res.bor_c_annee_mini;
      form.bor_c_annee_maxi = res.bor_c_annee_maxi;
      form.bor_n_seq = res.bor_n_seq;
      form.bor_n_papier_metrage = res.bor_n_papier_metrage;
      
      state.basculeBord = form.bor_c_sort_versement == 'Conservation intégrale' || form.bor_c_sort_versement == 'Conservation partielle' ? "d'élimination" : 'de versement';
      state.typeBord = form.bor_c_sort_versement == 'Conservation intégrale' || form.bor_c_sort_versement == 'Conservation partielle' ? 1 : 2;

      state.loaderEnabled = false;
    });

    const anneesExtremes = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticlesMaxAnnees/" + parseInt(localStorage.getItem("currentBord") || "0"));
      //console.log(myListLiv);
      form.bor_c_annee_mini = myListLiv.results.art_n_annee_mini;
      form.bor_c_annee_maxi = myListLiv.results.art_n_annee_maxi;
    };


    const totMetrage = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticlesML/" + parseInt(localStorage.getItem("currentBord") || "0"));
      form.bor_n_papier_metrage = Math.round(myListLiv.results.art_metrage_lineaire * 100) / 100 as any;
    };

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      form,
      handleClose,
      showInput,
      handleInputConfirm,
      inputValue,
      inputVisible,
      InputRef,
      editBordereau,
      pageTitle,
      totArticle,
      anneesExtremes,
      switchTypeBord,
      totMetrage
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

</script>
